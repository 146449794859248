<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;短信配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
      <el-form-item label="阿里短信AccessKey">
        <el-input v-model="form.sms_access_key_id"></el-input>
      </el-form-item>
      <el-form-item label="阿里短信AccessSecret">
        <el-input v-model="form.sms_access_secret"></el-input>
      </el-form-item>
      <el-form-item label="阿里短信签名">
        <el-input v-model="form.sms_sign_name"></el-input>
      </el-form-item>
      <el-form-item label="每日单个IP短信限制数">
        <el-input-number v-model.number="form.sms_limit" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        sms_access_key_id: "",
        sms_access_secret: "",
        sms_sign_name: "",
        sms_limit: ""
      }
    };
  },
  created() {
    this.initSms();
  },
  methods: {
    initSms() {
      getSystemConfigApi({ type: "sms" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>